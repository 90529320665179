/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
// import _ from 'lodash'
// import { css, jsx } from '@emotion/core'

import {
  Page,
  Flex,
  Box,
  Image,
  Link,
  // H1,
  // Text,
  // FeatherIcon,
  Hr,
  // Circle,
  Truncate,
  Paper,
  H2,
} from '../elements'
import {
  BoardgamePreview,
  // TournamentPrizes,
  Description,
  PeoplePreview,
  AnimationEntry,
  Album,
  // More,
  FacebookCircle,
  HeadingComplex,
  SectionBoardgames,
  SectionAnimations,
  SectionGuests,
  Youtube,
  SEO,
  AnimationCategories,
  PlaceIcon,
} from '../components'

const ExhibitorTemplate = ({ data: { exhibitor } }) => {
  const {
    relativePath,
    nameCanonical,
    name,
    nameFallback,
    place,
    featuredImage,
    facebook,
    website,
    youtubeVideo: { id: videoId },
    bio,
    description,
    album = [],
    // more,
    category,
    animations = [],
    boardgames = [],
    guests = [],
  } = exhibitor

  const mainColor = 'exhibitor'
  // const category = _.camelCase(categories[0])

  return (
    <Page>
      {({ t, currentLocale }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `${relativePath}`,
              title: nameFallback[currentLocale],
              // description: true,
            }}
          />
          <HeadingComplex
            {...{
              type: 'exhibitor',
              // icon: category,
              // iconColor: `flag_${category}`,
              nameCanonical,
              name: nameFallback,
              // place,
              image: featuredImage,
            }}
          />

          <Flex.container
            {...{
              alignItems: 'center',
              justifyContent: 'space-between',
              // py: 0,
            }}
          >
            <AnimationCategories
              {...{
                categories: [category],
                bg: mainColor,
                color: 'textAlt',
                fontSize: 1,
                mt: 2,
                mb: 1,
                // container: true,
              }}
            />
            <PlaceIcon
              {...{
                // icon: category,
                place,
                tPlace: t(place),
                color: 'textAlt',
                fill: mainColor,
              }}
            />
          </Flex.container>

          <Flex.container
            {...{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Truncate {...{}}>
              {website && (
                <Link
                  {...{
                    href: website,
                    target: '_blank',
                    rel: 'noopener noreferrer',

                    color: mainColor,
                    fontSize: [1, 1, 2],
                  }}
                >
                  {website}
                </Link>
              )}
            </Truncate>
            <FacebookCircle
              {...{
                color: 'textAlt',
                backgroundColor: mainColor,
                link: facebook,
              }}
            />
          </Flex.container>

          <Description
            {...{
              description: bio,
              title: t('presentation'),
              icon: 'smile',
              color: mainColor,
            }}
          />
          <Description
            {...{
              description,
              color: mainColor,
              title: t('atBGF'),
              icon: 'flag',
            }}
          />

          {!!(album && album.length) && (
            <Flex.container>
              <Album {...{ album }} />
            </Flex.container>
          )}

          {videoId && (
            <Box.container
              {...{
                width: '100%',
                maxWidth: [300, 300, 600],
                // height: '100%',
                height: [300 / 1.777, 300 / 1.777, 600 / 1.777],
                // alignText: 'center',
                m: 'auto',
              }}
            >
              <Youtube
                {...{
                  videoId,
                  css: {
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
            </Box.container>
          )}

          <SectionBoardgames {...{ boardgames, t }} />
          <SectionAnimations {...{ animations, t }} />
          <SectionGuests {...{ guests, t }} />
        </React.Fragment>
      )}
    </Page>
  )
}

export const pageQuery = graphql`
  query Exhibitor($id: String!) {
    exhibitor(id: { eq: $id }) {
      nameCanonical
      name {
        fr
        en
        nl
      }
      nameFallback {
        fr
        en
        nl
      }
      relativePath
      description {
        en
        fr
        nl
      }
      website
      youtubeVideo {
        id
        url
        opts {
          startSeconds
        }
      }
      facebook
      place
      category
      bio {
        en
        fr
        nl
      }
      featuredImage {
        id
        url
        size
        thumbnails {
          small {
            height
            width
            url
          }
          large {
            url
            width
            height
          }
        }
      }
      album {
        id
        url
        size
        thumbnails {
          small {
            url
            width
            height
          }
          large {
            url
            width
            height
          }
          full {
            url
            width
            height
          }
        }
      }

      animations {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        days
        start
        end
        categories
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      boardgames {
        relativePath
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        pitch {
          en
          fr
          nl
        }
        duration
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      guests {
        nameCanonical
        name {
          fr
          en
          nl
        }
        nameFallback {
          fr
          en
          nl
        }
        status
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`

export default ExhibitorTemplate
